import admin from '@/plugins/apis/admin'
import factory from '@/plugins/apis/factory'
import home from '@/plugins/apis/home'
import chat from '@/plugins/apis/chat'
export const api = {
    ...admin,
    ...factory,
    ...home,
    ...chat,
}
