import common from './common';

const baseUrl = common.baseUrl;

export default {
  "adminLogin": `${baseUrl}Admin/login`, // 登录
  "adminLogout": `${baseUrl}Admin/logout`, // 登出
  "adminCheckLogin": `${baseUrl}Admin/check_login`, // 验证是否登录
  "adminLookupAccountInfo": `${baseUrl}Admin/account/info`,

  'adminAdmins': `${baseUrl}Admin/admins`, // 后台管理员
  'adminUsers': `${baseUrl}Admin/users`, // 平台用户
  'adminUsersRecharge': `${baseUrl}Admin/users/recharge`, // 平台充值
  'adminUsersTypes': `${baseUrl}Admin/user_types`, // 用户类型
  'adminCredentialDefinitions': `${baseUrl}Admin/credential_definitions`, // 证书定义

  'adminRoles': `${baseUrl}Admin/roles`, // 角色管理

  'adminUserLoginLogs':`${baseUrl}Admin/users/login-logs`,  // 登录日志
  'adminPermissionGroups': `${baseUrl}Admin/permission_groups`, // 后台接口分组
  'adminPermissions': `${baseUrl}Admin/permissions`, // 后台接口分组

  // 菜单处理
  'adminMenus': `${baseUrl}Admin/menus`, // 后台菜单
  'adminMenusClearCache': `${baseUrl}Admin/menus/cache/clear`, // 清空菜单缓存

  // 配置中心
  'adminConfigs': `${baseUrl}Admin/configs`, // 配置信息获取
  'adminConfigsUploadLogo': `${baseUrl}Admin/configs/upload/logo`, // Logo图上传
  'adminConfigsUploadIcon': `${baseUrl}Admin/configs/upload/icon`, // Icon图上传

  // 站点协议
  'adminAgreements': `${baseUrl}Admin/agreements`,

  // 文章 帮助中心
  'adminArticles': `${baseUrl}Admin/articles`,

  // 物流公司
  'adminExpresses': `${baseUrl}Admin/expresses`,

  // 编辑器上传
  'adminEditor': `${baseUrl}Admin/editor/upload`,

  // 短信日志
  'adminSmsLogs': `${baseUrl}Admin/sms_logs`, // 短信日志
  'adminSmsSigns': `${baseUrl}Admin/sms_signs`, // 短信签名

  // 商品类目管理
  'adminGoodsClasses': `${baseUrl}Admin/goods_classes`, // 商品类目
  'adminGoodsClassesUploadThumb': `${baseUrl}Admin/goods_classes/upload/thumb`, // 分类缩略图上传
  'adminGoodsClassesClearCache': `${baseUrl}Admin/goods_classes/cache/clear`, // 清空商品类目缓存

  // 商品分组管理
  'adminGoodsGroups': `${baseUrl}Admin/platform_goods_groups`, // 商品类目
  'adminGoodsGroupsUploadBanner': `${baseUrl}Admin/platform_goods_groups/upload/banner`, // 分类缩略图上传
  'adminGoodsGroupsClearCache': `${baseUrl}Admin/platform_goods_groups/cache/clear`, // 清空商品类目缓存
  'adminFactoryGoodsGroups': `${baseUrl}Admin/factory_goods_groups`, // 商户商品组
  'adminAuditFactoryGoodsGroups': `${baseUrl}Admin/factory_goods_groups/change_status`, // 审核商户商品组
  'adminGoodsGroupsGoodsList': `${baseUrl}Admin/platform_goods_groups/goods/list`, // 商品组下的商品
  'adminGoodsGroupsOutsideGoodsList': `${baseUrl}Admin/platform_goods_groups/goods/outside`, // 非指定商品组的商品
  'adminGoodsGroupsAddGoods': `${baseUrl}Admin/platform_goods_groups/goods/add`, // 添加商品到商品组
  'adminGoodsGroupsDelGoods': `${baseUrl}Admin/platform_goods_groups/goods/del`, // 添加商品到商品组

  // 厂家管理
  'adminFactories': `${baseUrl}Admin/factories`,
  'adminFactoriesProxy': `${baseUrl}Admin/factories/proxy`,

  // 商品品牌管理
  adminGoodsBrands: `${baseUrl}Admin/goods_brands`, // 商品品牌
  adminGoodsBrandsUploadThumb: `${baseUrl}Admin/goods_brands/upload/thumb`, // 品牌缩略图上传
  adminGoods: `${baseUrl}Admin/goods`, // 商品列表
  adminAreas: `${baseUrl}Admin/areas`, // 全国地址
  adminAreasClearCache: `${baseUrl}Admin/areas/cache/clear`, // 清空商品类目缓存
  adminAdvPositions: `${baseUrl}Admin/adv_positions`,  // 广告位
  adminAdvs: `${baseUrl}Admin/advs`,  // 广告管理
  adminAdvsUploadThumb: `${baseUrl}Admin/advs/upload/thumb`, // 品牌缩略图上传
  adminOrders: `${baseUrl}Admin/orders`, // 订单管理
  adminOrderComments: `${baseUrl}Admin/order_comments`, // 订单评论
  adminDistributionLogs: `${baseUrl}Admin/distribution_logs`, // 分销日志
  adminOrderSettlements: `${baseUrl}Admin/order_settlements`, // 结算日志
  adminMoneyLogs: `${baseUrl}Admin/money_logs`, // 用户资金日志
  adminCashes: baseUrl + 'Admin/cashes', // 资金提现
  adminApportion: `${baseUrl}Admin/apportion`, // 分配会员
  adminIntegralGoodsClasses: `${baseUrl}Admin/integral_goods_classes`, // 商品类目
  adminIntegralGoods: `${baseUrl}Admin/integral_goods`, // 积分商品
  adminIntegralOrders: `${baseUrl}Admin/integral_orders`, // 积分订单
  adminStatistics: `${baseUrl}Admin/statistics`, // 数据统计
  adminCoupons: `${baseUrl}Admin/coupons`, // 优惠券
  adminCouponLogs: `${baseUrl}Admin/coupon_logs`, // 优惠券日志
  adminSalesmen: `${baseUrl}Admin/salesmen`, // 业务员列表
  adminSalesmanCustomers: (salesmanId) => `${baseUrl}Admin/salesmen/customers/${salesmanId}`,
  adminSalesteams: `${baseUrl}Admin/salesteams`, // 业务员客户
  adminActivities: `${baseUrl}Admin/activities`, // 活动列表
  adminActivitiesAudit: (status, ids) => `${baseUrl}Admin/activities/audit/${status}/${ids.join(',')}`,
  adminActivitiesGoods: rule => `${baseUrl}Admin/activities/${rule}/goods`,
  adminActivitiesGoodsAudit: (rule, id) => `${baseUrl}Admin/activities/${rule}/audit/goods/${id}`,
  adminCancelUserDeviceCheck: (type, id) => `${baseUrl}Admin/users/${type}/${id}/cancel_device_check`,
  adminDrugstoresSheets: `${baseUrl}Admin/drugstores/sheets`,
  adminDrugstoresSheetsChangeStatus: `${baseUrl}Admin/drugstores/sheets/change_status`,
  adminDrugstoresWithoutSalesman: (salesmanId) => `${baseUrl}Admin/drugstores/without/salesman/${salesmanId}`, // 不是业务员的客户的药店
  adminDrugstoresToSalesman: (salesmanId) => `${baseUrl}Admin/salesmen/customers/${salesmanId}`, // 给业务员设置客户
};
