import common from './common';

const baseUrl = common.baseUrl;
export default {
  /**
   * 厂家后台接口
   * <www.qingwuit.com>
   */

  // 登录验证
  "factoryLogin": baseUrl + "Factory/login", // 登录
  "factoryLogout": baseUrl + "Factory/logout", // 登出
  "factoryCheckLogin": baseUrl + "Factory/check_login", // 检测是否登录

  // 厂家菜单
  'factoryMenus': baseUrl + 'Factory/menus',

  // 规格属性
  'factoryGoodsAttrs': baseUrl + 'Factory/goods_attrs',

  // 商品管理
  'factoryGoods': baseUrl + 'Factory/goods',
  'factoryGoodsSearch': baseUrl + 'Factory/goods_search',
  'factoryPlatformGoodsClasses': baseUrl + 'Factory/platform_goods_classes',  // 获取厂家有权的栏目信息
  'factoryGoodsUpload': baseUrl + 'Factory/goods/upload/images',
  'factoryGoodsChangeField': baseUrl + 'Factory/goods/change/field/value',
  'factoryGoodsModifyField': baseUrl + 'Factory/goods/modify/field',

  // 会员管理
  'factoryDrugstoreTypes': baseUrl + 'Factory/users/types', // 会员类型列表
  'factoryDrugstores': baseUrl + 'Factory/users/list', // 会员列表
  'factoryDrugstoreRemove': baseUrl + 'Factory/users/del', //删除会员关联
  'factoryDrugstoreInfo': baseUrl + 'Factory/users/info', //会员信息

  // 商品权限
  'factoryGoodsVisibility': baseUrl + 'Factory/goods_visibility',
  'factoryGoodsVisibilityVisiblePrice': baseUrl + 'Factory/goods_visibility/price_visible', // 显示价格
  'factoryGoodsVisibilityGoodsHidden': baseUrl + 'Factory/goods_visibility/goods_hidden', // 商品隐藏
  'factoryGoodsVisibilityToggleStatus': baseUrl + 'Factory/goods_visibility/toggle_status', // 修改状态（商品隐藏或价格显示）
  'factoryGoodsVisibilitySearchGoods': baseUrl + 'Factory/goods_visibility/search_goods', // 查询商品
  'factoryGoodsVisibilitySearchDrugstore': baseUrl + 'Factory/goods_visibility/search_drugstores', // 查询厂家
  'factoryGoodsVisibilityCustomGoodsPrice': baseUrl + 'Factory/goods_visibility/custom_goods_price', // 定制价格

  // 商品类目管理
  'factoryGoodsClasses': baseUrl + 'Factory/goods_classes', // 商品类目
  'factoryGoodsClassesUploadThumb': baseUrl + 'Factory/goods_classes/upload/thumb', // 分类缩略图上传
  'factoryGoodsClassesClearCache': baseUrl + 'Factory/goods_classes/cache/clear', // 清空商品类目缓存

  // 商品分组管理
  'factoryGoodsGroups': baseUrl + 'Factory/factory_goods_groups', // 商品类目
  'factoryGoodsGroupsUploadBanner': baseUrl + 'Factory/factory_goods_groups/upload/banner', // 分类缩略图上传
  'factoryGoodsGroupsClearCache': baseUrl + 'Factory/factory_goods_groups/cache/clear', // 清空商品类目缓存
  'factoryGoodsGroupsGoodsList': baseUrl + 'Factory/factory_goods_groups/goods/list', // 商品组下的商品
  'factoryGoodsGroupsOutsideGoodsList': baseUrl + 'Factory/factory_goods_groups/goods/outside', // 非指定商品组的商品
  'factoryGoodsGroupsAddGoods': baseUrl + 'Factory/factory_goods_groups/goods/add', // 添加商品到商品组
  'factoryGoodsGroupsGoodsDelete': baseUrl + 'Factory/factory_goods_groups/goods/delete', // 删除商品

  // 编辑器上传
  'factoryEditor': baseUrl + 'Factory/editor/upload',

  // 品牌管理
  'factoryGoodsBrands': baseUrl + 'Factory/goods_brands',

  // 订单管理
  'factoryOrders': baseUrl + 'Factory/orders',
  factoryInvoices(id) {
    return baseUrl + 'Factory/orders/'+ id + '/invoices'
  },

  // 订单售后
  'factoryRefunds': baseUrl + 'Factory/refunds',

  // 物流公司
  'factoryExpresses': baseUrl + 'Factory/expresses',

  // 订单评论
  'factoryOrderComments': baseUrl + 'Factory/order_comments',

  // 配置中心处理
  'factoryConfigs': baseUrl + 'Factory/configs',

  // 全国省市区获取
  'factoryAreas': baseUrl + 'Factory/areas',

  // 运费配置
  'factoryFreights': baseUrl + 'Factory/freights',

  // 分销活动
  'factoryDistributions': baseUrl + 'Factory/distributions',

  // 分销日志
  'factoryDistributionLogs': baseUrl + 'Factory/distribution_logs',

  // 优惠券
  'factoryCoupons': baseUrl + 'Factory/coupons',
  'factoryCouponLogs': baseUrl + 'Factory/coupon_logs', // 优惠券日志

  // 满减
  'factoryFullReductions': baseUrl + 'Factory/full_reductions',

  // 秒杀
  'factorySeckills': baseUrl + 'Factory/seckills',

  // 拼团
  'factoryCollectives': baseUrl + 'Factory/collectives',
  'factoryCollectiveLogs': baseUrl + 'Factory/collective_logs',

  // 结算日志
  'factoryOrderSettlements': baseUrl + 'Factory/order_settlements',

  // 厂家资金日志
  'factoryMoneyLogs': baseUrl + 'Factory/money_logs',

  // 资金提现
  'factoryCashes': baseUrl + 'Factory/cashes',

  // 数据统计
  'factoryStatistics': baseUrl + 'Factory/statistics',

  // 活动专区（特价、促销、优惠、打折、秒杀）
  factoryActivities(rule, id) {
    if (rule == null) return baseUrl + 'Factory/activities';
    if (id == null) return `${baseUrl}Factory/activities/${rule}/goods`;
    return `${baseUrl}Factory/activities/${rule}/goods/${id}`;
  },
  factoryActivitiesOutsideGoods(rule) {
    return `${baseUrl}Factory/activities/${rule}/outside_goods`;
  },
  factoryActivitiesPresentsGoods: baseUrl + 'Factory/activities/presents_goods',

  factorySalesteam: baseUrl + 'Factory/salesteam',
  factorySalesteams: baseUrl + 'Factory/salesteams',
  factorySalesteamsSearch: baseUrl + 'Factory/salesteams_search',
  factorySalesteamsApportion: baseUrl + 'Factory/salesteams/apportion',
  factorySalesteamsGoods: baseUrl + 'Factory/salesteams/goods',
};
