// initial state
const state = {
  isFactory: false,
  factoryVerify: 0,
}

// getters
const getters = {}

// actions
const actions = {
  factory_verify(context, e) {
    if (e.code == 200) {
      state.isFactory = true;
      state.factory_verify = e.data.factory_verify;
    }
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
