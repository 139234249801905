export default [
  // 登录
  {
    path: '/Factory/login',
    name: 'factory_login',
    component: () => import('@/views/Factory/login'),
  },

  // 后台界面
  {
    path: "/Factory/index",
    name: "factory_index",
    component: () => import("@/views/Factory/index"),
    children: [
      {
        path: "/Factory/index",
        name: "factory_default",
        meta: { title: '' }, // 打开默认页面
        component: () => import("@/views/Factory/default"),
      },

      // 配置中心
      // {
      //   path: '/Factory/configs',
      //   name: 'factory_configs',
      //   meta: { title: '配置中心' },
      //   component: () => import("@/layouts/BlankLayout"),
      //   children: [
      //     { path: '/Factory/configs/base', name: "factory_configs_base", component: () => import("@/views/Factory/configs/base") },
      //     { path: '/Factory/configs/face', name: "factory_configs_face", component: () => import("@/views/Factory/configs/face/index") },
      //     //{ path: "map", name: "factory_configs_map", component: () => import("@/views/Factory/configs/map/index") },
      //     { path: "/Factory/configs/security", name: "factory_configs_security", component: () => import("@/views/Factory/configs/security") },
      //     { path: "/Factory/configs/slide", name: "factory_configs_slide", component: () => import("@/views/Factory/configs/slide/index") },
      //   ],
      // },
      { path: '/Factory/configs/base', name: "factory_configs_base", component: () => import("@/views/Factory/configs/base") },
      { path: '/Factory/configs/face', name: "factory_configs_face", component: () => import("@/views/Factory/configs/face/index") },
      { path: "/Factory/configs/security", name: "factory_configs_security", component: () => import("@/views/Factory/configs/security") },
      { path: "/Factory/configs/slide", name: "factory_configs_slide", component: () => import("@/views/Factory/configs/slide/index") },
      { path: "/Factory/configs/qualification", name: "factory_configs_qualification", component: () => import("@/views/Factory/configs/qualification") },

      // 商品管理
      { path: "/Factory/goods", name: "factory_goods", component: () => import("@/views/Factory/goods/index") }, // 商品列表
      // { path: "/Factory/goods/chose_class/:id?", name: "factory_goods_chose_class", component: () => import("@/views/Factory/goods/chose_class") }, // 商品类目选择
      { path: "/Factory/goods/form/:id?", name: "factory_goods_form", component: () => import("@/views/Factory/goods/form") }, // 商品编辑

      // 商品可见管理
      { path: "/Factory/goods_visibility/list/:id", name: "goods_visibility", component: () => import("@/views/Factory/goods_visibility/index") },
      { path: "/Factory/goods_visibility/form/:id?", name: "goods_visibility_form", component: () => import("@/views/Factory/goods_visibility/form") },

      // 商品类目
      { path: "/Factory/goods_classes", name: "factory_goods_classes", component: () => import("@/views/Factory/goods_classes/index") },
      { path: "/Factory/goods_classes/form/:id?", name: "factory_goods_classes_form", component: () => import("@/views/Factory/goods_classes/form") },

      // 商品分组
      { path: "/Factory/goods_groups", name: "factory_goods_groups", component: () => import("@/views/Factory/goods_groups/index") },
      { path: "/Factory/goods_groups/form/:id?", name: "factory_goods_groups_form", component: () => import("@/views/Factory/goods_groups/form") },
      { path: "/Factory/goods_groups/goods/:id", name: "factory_goods_groups_goods", component: () => import("@/views/Factory/goods_groups/goods") },

      // 属性规格
      { path: "/Factory/goods_attrs", name: "factory_goods_attrs", component: () => import("@/views/Factory/goods_attrs/index") }, // 商品列表
      { path: "/Factory/goods_attrs/form/:id?", name: "factory_goods_attrs_form", component: () => import("@/views/Factory/goods_attrs/form") }, // 商品编辑

      // 订单管理
      { path: "/Factory/orders/:type?", name: "factory_orders", component: () => import("@/views/Factory/orders/index") },
      { path: "/Factory/orders/form/:id?", name: "factory_orders_form", component: () => import("@/views/Factory/orders/form") },

      // 售后管理
      { path: "/Factory/orders/refund/:id", name: "factory_refunds_form", component: () => import("@/views/Factory/refunds/index") },

      // 订单评论
      { path: "/Factory/order_comments", name: "factory_order_comments", component: () => import("@/views/Factory/order_comments/index") },
      { path: "/Factory/order_comments/form/:id?", name: "factory_order_comments_form", component: () => import("@/views/Factory/order_comments/form") },

      // 配送运费
      { path: "/Factory/freights/form", name: "factory_freights", component: () => import("@/views/Factory/freights/form") },

      // 分销活动
      { path: "/Factory/distributions", name: "factory_distributions", component: () => import("@/views/Factory/distributions/index") },
      { path: "/Factory/distributions/form/:id?", name: "factory_distributions_form", component: () => import("@/views/Factory/distributions/form") },

      // 分销日志
      { path: "/Factory/distribution_logs", name: "factory_distribution_logs", component: () => import("@/views/Factory/distribution_logs/index") },

      // 结算日志
      { path: "/Factory/order_settlements", name: "factory_order_settlements", component: () => import("@/views/Factory/order_settlements/index") },
      {
        path: "/Factory/order_settlements/form/:id",
        name: "factory_order_settlements_form",
        component: () => import("@/views/Factory/order_settlements/form")
      },

      // 优惠券
      { path: "/Factory/coupons", name: "factory_coupons", component: () => import("@/views/Factory/coupons/index") },
      { path: "/Factory/coupons/form/:id?", name: "factory_coupons_form", component: () => import("@/views/Factory/coupons/form") },
      { path: "/Factory/coupon_logs", name: "factory_coupon_logs", component: () => import("@/views/Factory/coupon_logs/index") }, // 优惠券日志

      // 满减
      { path: "/Factory/full_reductions", name: "factory_full_reductions", component: () => import("@/views/Factory/full_reductions/index") },
      { path: "/Factory/full_reductions/form/:id?", name: "factory_full_reductionsform", component: () => import("@/views/Factory/full_reductions/form") },

      // 秒杀
      { path: "/Factory/seckills", name: "factory_seckills", component: () => import("@/views/Factory/seckills/index") },
      { path: "/Factory/seckills/form/:id?", name: "factory_seckills_form", component: () => import("@/views/Factory/seckills/form") },

      // 拼团
      { path: "/Factory/collectives", name: "factory_collectives", component: () => import("@/views/Factory/collectives/index") },
      { path: "/Factory/collectives/form/:id?", name: "factory_collectives_form", component: () => import("@/views/Factory/collectives/form") },
      { path: "/Factory/collective_logs/:collective_id", name: "factory_collective_logs", component: () => import("@/views/Factory/collective_logs/index") },

      // 厂家资金日志
      { path: "/Factory/money_logs", name: "factory_money_logs", component: () => import("@/views/Factory/money_logs/index") },

      // 资金提现
      { path: "/Factory/cashes", name: "factory_cashes", component: () => import("@/views/Factory/cashes/index") },
      { path: "/Factory/cashes/form", name: "factory_cashes_form", component: () => import("@/views/Factory/cashes/form") },

      // 在线聊天
      { path: "/Factory/chats", name: "factory_chats", component: () => import("@/views/Factory/chats/index") },

      // 会员列表
      { path: "/Factory/users", name: "factory_users", component: () => import("@/views/Factory/members/users") },

      // 数据统计
      { path: "/Factory/statistics/order", name: "factory_statistics_order", component: () => import("@/views/Factory/statistics/order") },

      // 活动专区
      { path: '/Factory/activities', name: 'factory_activities', component: () => import('@/views/Factory/activities/index') },
      { path: '/Factory/activities/form/:id?', name: 'factory_activities_form', component: () => import('@/views/Factory/activities/form') },

      { path: '/Factory/salesteams', name: 'factory_salesteams', component: () => import('@/views/Factory/salesteams/index') },

    ]
  },
];
