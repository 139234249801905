function install(Vue) {
  Vue.prototype.$bubble = function (type, data, ack) {
    const vm = this;
    let stop = false;
    if (vm.$bubbleListeners && Array.isArray(vm.$bubbleListeners[type])) {
      for (const fn of vm.$bubbleListeners[type].slice()) {
        if (fn(data) === true) {
          stop = true;
        }
      }
    }
    if (!stop && vm.$parent) {
      vm.$parent.$bubble(type, data, ack);
    } else if (typeof ack === "function") {
      ack();
    }
  };

  Vue.prototype.$listen = function (type, fn, once = false) {
    const vm = this;
    if (!vm.$bubbleListeners) vm.$bubbleListeners = Object.create(null);
    if (!vm.$bubbleListeners[type]) vm.$bubbleListeners[type] = [];
    let listener = fn;
    if (once) listener = function (...args) {
      const index = vm.$bubbleListeners[type].findIndex(f => f === listener);
      if (index >= 0) vm.$bubbleListeners[type].splice(index, 1);
      fn(...args);
    }
    this.$bubbleListeners[type].push(listener);
  }
}

export default {
  install
}
