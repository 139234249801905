import Vue from 'vue'
import store from '@/store/index'
import router from '@/plugins/router'
import Antd from 'ant-design-vue'
import {Icon} from 'ant-design-vue'
import App from '@/views/App'
import {post, get, put, deletes, postfile, toJson, isEmpty, apiHandle} from '@/plugins/http' // 请求方式中间件
import {api} from '@/plugins/api' // 后端API
import {getSession, returnInfo, formatFloat} from '@/plugins/function' // 辅助方法
import '@/plugins/css/home.css' // 首页样式
import '@/plugins/css/style.scss' // 公共样式
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import PageView from '@/layouts/PageView'
import Teleport from '@/layouts/Teleport'
import VueLazyload from 'vue-lazyload' // 懒加载图片
import 'babel-polyfill' // 兼容IE
import Bubble from './plugins/bubble'

Vue.prototype.$api = api;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$delete = deletes;
Vue.prototype.$drop = deletes;
Vue.prototype.$postfile = postfile;
Vue.prototype.$toJson = toJson;
Vue.prototype.$isEmpty = isEmpty;
Vue.prototype.$apiHandle = apiHandle;
Vue.prototype.$getSession = getSession; // 获取session
Vue.prototype.$returnInfo = returnInfo; // api返回信息做处理
Vue.prototype.$formatFloat = formatFloat; // 浮点型格式化

Vue.config.productionTip = true;

// 字体图标 iconFont
let fontjs = require('@/plugins/font');
const AFont = Icon.createFromIconfontCN({
  scriptUrl: fontjs,
});

Vue.use(Antd)
Vue.use(PageView)
Vue.use(VueLazyload)
Vue.use(Viewer)
Vue.use(Bubble)
Vue.component('a-font', AFont)
Vue.component('page-view', PageView)
Vue.component('teleport', Teleport)

// 跳转页面回到顶部
router.afterEach(function () {
  window.scrollTo(0, 0)
})

Vue.use(VueRouter)

new Vue({
  el: '#app',
  store,
  components: {App},
  router,
})
