import Vue from 'vue'
import Vuex from 'vuex'

// 厂家
import factoryLogin from '@/store/modules/factory/login'

// PC端
import homeLogin from '@/store/modules/home/login' // 登录
import homeFactory from '@/store/modules/home/factory' // 厂家
import homeCommon from '@/store/modules/home/common' // 公共信息
import homeCart from '@/store/modules/home/cart' // 购物车

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    factoryLogin,
    homeLogin,
    homeFactory,
    homeCommon,
    homeCart,
  },
  strict: false,
})
