<template>
  <div class="page-view" :class="{bubbled}">
    <div :style="{backgroundColor, padding, margin}">
      <slot />
    </div>
    <slot name="footer"/>
  </div>
</template>

<script>
export default {
  name: 'page-view',

  props: {
    margin: {
      default: '16px',
    },
    padding: {
      default: 0,
    },
    backgroundColor: {
      default: 'transparent',
    },
    title: String,
    subtitle: String,
    actions: Array,
    tabList: Array,
    tabKey: [Number, String, Symbol],
    descriptions: Array,
    statistics: Array,
  },

  data() {
    return {
      bubbled: false,
    }
  },

  computed: {
    pageMeta() {
      const data = {
        title: this.title,
        subtitle: this.subtitle,
        actions: this.actions ?? [],
        descriptions: this.descriptions ?? [],
        statistics: this.statistics ?? [],
        tabList: this.tabList ?? [],
        tabKey: this.tabKey,
      };
      const token = [...Object.values(data)]
        .filter(Boolean)
        .map(a => JSON.stringify(a)).join('-');
      return { data, token };
    },
  },

  created() {
    this.$bubble('page:start');

    this.$watch(
      () => this.pageMeta.token,
      this.bubblePageMeta,
      { immediate: true },
    );
  },

  methods: {
    bubblePageMeta() {
      this.$bubble('page:meta', this.pageMeta.data, () => {
        this.$nextTick(() => {
          if (!this.bubbled) {
            this.bubbled = true;
            this.$bubble('page:end');
            this.$emit('bubbled')
          }
        });
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.page-view {
  opacity: 0;

  &.bubbled {
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }
}
</style>
