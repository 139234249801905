<template>
  <a-config-provider :auto-insert-space-in-button="false" :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<script>
export default {
  data() {
    return {
      locale: window.antd.locales.zh_CN,
    };
  }
}
</script>

<style lang="scss">
#app {
  color: #333;
  font-size: 14px;
  min-height: 100vh;
}

.custom-cascader {
  height: auto;
  min-height: 108px;
  max-height: 500px;

  .ant-cascader-menu {
    height: 100%;
  }
}

.custom-editable-table {
  th, td {
    &:not(:last-child) {
      border-right: 1px solid #eee;
    }
  }

  td {
    padding: 0 !important;

    input {
      height: 100%;
      border-color: transparent;
      width: 100%;
      //background: transparent;
      padding: 8px 16px;
      border-radius: 0;

      &:focus,
      &:hover {
        border-color: #1890ff;
        box-shadow: none;
      }
    }

    > label {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .ant-table-placeholder {
    border-radius: 0;
  }
}

input[type="color"] {
  border: none;
  height: 32px;
  width: 32px;
  vertical-align: middle;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    box-shadow: none;
  }

  &::-webkit-color-swatch {
    background: transparent;
    border-radius: 4px;
  }
}

.status-pass {
  border-color: #67c23a;
  color: #67c23a;
}

.status-fail {
  border-color: #ff4d4f;
  color: #ff4d4f;
}

.status-wait {
  border-color: #1a1a1a;
  color: #1a1a1a;
}

.goods_price {
  color: red;
  font-weight: bold;
}

.custom-tab {
  display: flex;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  zoom: 1;
  margin: 0 0 16px;
  border-bottom: 1px solid #e8e8e8;
  outline: none;

  &-item {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 100%;
    margin: 0 32px 0 0;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    color: #1a1a1a;

    .anticon {
      margin-right: 8px;
    }

    &.display-count::before {
      position: absolute;
      display: block;
      content: attr(data-count);
      top: 0;
      right: 0;
      background-color: #f00;
      color: #fff;
      padding: 4px;
      font-size: 11px;
      border-radius: 16px;
      min-width: 22px;
      line-height: 1;
      text-align: center;
      transform: translate3d(25%, 0, 0);
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 2px;
      background-color: transparent;
    }

    small {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      border-radius: 10px;
      color: #fff;
      background: #f5222d;
      transform: translate(50%, -5%) scale(.75);
      pointer-events: none;
    }

    &.active {
      color: #1890ff;
      font-weight: bold;

      &::after {
        background-color: #1890ff;
      }
    }
  }
}
</style>
